export default {
	name: 'HomeAccountDetailPage',
	props: {
		dialogAccountType: {
			type: String,
			required: true
		},
		propertyState: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			// 翻页配置
			pagesObj: {
				page: 1,
				pagesize: 10,
				total: 0
			},
			// 获取列表数据
			getAllList: [],
			loadTable: false,
			typelist:[],
			frequnitList: [],
		}
	},
	created() {
		this.getdatabind('typelist', 'LABELTYPE')
		this.getdatabind('frequnitList', 'FREQUNIT')
		switch (this.dialogAccountType) {
			case 'pe':
				this.getHomeList()
				break;

			case 'acc':
				this.getAccountList()
				break;

			case 'loc':
				this.getLoclist()
				break;

			case 'rctask':
				this.getrctaskList()
				break;

			case 'wotemp':
				this.getwotempList()
				break;

			default:
				break;
		}

	},
	methods: {
		getFrequnit(value) {
			for (let i = 0; i < this.frequnitList.length; i++) {
				if (value == this.frequnitList[i].value) {
					return this.frequnitList[i].description;
				}
			}
			return "";
		},
		labeltypeFormatter(row){
			if(row.labeltype){
				for (let index = 0; index < this.typelist.length; index++) {
					if(this.typelist[index].value.toLocaleUpperCase() == row.labeltype.toLocaleUpperCase()){
						return this.typelist[index].description
					}
					
				}
			}else{
				return ''
			}
			
		},
		getroleFormatter(row) {
			
			let result = [];
			if (row.isdataadmin == '1') {
				result.push(this.$t('account.dataAdmin'));
			}
			if (row.ispemgr == '1') {
				result.push(this.$t('account.pemgr'));
			}
			if (row.isgeneraluser == '1') {
				result.push(this.$t('account.generalUser'));
			}
			return result.join('、');
		},
		getdatabind(key, value) {
			this.$http.post('/common/databind', {
				hrid: this.$store.getters.loginid,
				penum: this.$store.getters.penum,
				"databindid": value
			}, {
				showLoading: false
			}).then((response) => {
				if (response.data.success) {
					// if (key == 'typelist') {
					// 	this[key] = response.data.content.map(item => {
					// 		item.value = item.value.toUpperCase()
					// 		return item
					// 	})
					// } else {
						this[key] = response.data.content;
					// }
				} else {
					this.$alert(response.data.message, this.$t('common.alertPrompt'), {
						type: 'error',
						center: true
					})
				}
			})
		},
		// 获取物业实体
		getHomeList(page) {
			this.loadTable = true
			if (page) {
				this.pagesObj.page = page
			} else {
				this.pagesObj.page = 1
			}
			this.$http
				.post('/home/homelist', {
					hrid: this.$store.getters.loginid,
					penum: this.$store.getters.penum,
					query: '',
					page: this.pagesObj.page,
					pagesize: this.pagesObj.pagesize,
				}, {
					showLoading: false
				})
				.then((response) => {
					this.loadTable = false
					if (response.data.success) {
						this.getAllList = response.data.content;
						this.$emit('titleNum', response.data.total)
						this.pagesObj = {
							...this.pagesObj,
							page: response.data.page,
							pagesize: this.pagesObj.pagesize,
							total: response.data.total
						}
					} else {
						this.$alert(response.data.message, this.$t('common.alertPrompt'), {
							type: 'error',
							center: true
						})
					}
				})
		},
		// 获取使用者账号
		getAccountList(page) {
			this.loadTable = true
			if (page) {
				this.pagesObj.page = page
			} else {
				this.pagesObj.page = 1
			}
			this.$http
				.post('/account/accountlist', {
					hrid: this.$store.getters.loginid,
					penum: this.$store.getters.penum,
					isallpe: this.propertyState + '', // 是否为所有物业
					query: '',
					page: this.pagesObj.page,
					pagesize: this.pagesObj.pagesize,
				}, {
					showLoading: false
				})
				.then((response) => {
					this.loadTable = false
					if (response.data.success) {
						this.getAllList = response.data.content;
						this.$emit('titleNum', response.data.total)
						this.pagesObj = {
							...this.pagesObj,
							page: response.data.page,
							pagesize: this.pagesObj.pagesize,
							total: response.data.total
						}
					} else {
						this.$alert(response.data.message, this.$t('common.alertPrompt'), {
							type: 'error',
							center: true
						})
					}
				})
		},
		// 获取巡检点
		getLoclist(page) {
			this.loadTable = true
			if (page) {
				this.pagesObj.page = page
			} else {
				this.pagesObj.page = 1
			}
			this.$http
				.post('/location/loclist', {
					hrid: this.$store.getters.loginid,
					penum: this.$store.getters.penum,
					isallpe: this.propertyState + '', // 是否为所有物业
					query: '',
					page: this.pagesObj.page,
					pagesize: this.pagesObj.pagesize,
				}, {
					showLoading: false
				})
				.then((response) => {
					this.loadTable = false
					if (response.data.success) {
						this.getAllList = response.data.content;
						this.$emit('titleNum', response.data.total)
						this.pagesObj = {
							...this.pagesObj,
							page: response.data.page,
							pagesize: this.pagesObj.pagesize,
							total: response.data.total
						}
					} else {
						this.$alert(response.data.message, this.$t('common.alertPrompt'), {
							type: 'error',
							center: true
						})
					}
				})
		},
		// 获取巡检计划
		getrctaskList(page) {
			this.loadTable = true
			if (page) {
				this.pagesObj.page = page
			} else {
				this.pagesObj.page = 1
			}
			this.$http
				.post('/rctask/rctasklist', {
					hrid: this.$store.getters.loginid,
					penum: this.$store.getters.penum,
					isallpe: this.propertyState + '', // 是否为所有物业
					active: "1",
					query: '',
					page: this.pagesObj.page,
					pagesize: this.pagesObj.pagesize,
					type: "RCTASK"
				}, {
					showLoading: false
				})
				.then((response) => {
					this.loadTable = false
					if (response.data.success) {
						this.getAllList = response.data.content;
						this.$emit('titleNum', response.data.total)
						this.pagesObj = {
							...this.pagesObj,
							page: response.data.page,
							pagesize: this.pagesObj.pagesize,
							total: response.data.total
						}
					} else {
						this.$alert(response.data.message, this.$t('common.alertPrompt'), {
							type: 'error',
							center: true
						})
					}
				})
		},
		// 获取工单模板
		getwotempList(page) {
			this.loadTable = true
			if (page) {
				this.pagesObj.page = page
			} else {
				this.pagesObj.page = 1
			}
			this.$http
				.post('/rctask/rctasklist', {
					hrid: this.$store.getters.loginid,
					penum: this.$store.getters.penum,
					isallpe: this.propertyState + '', // 是否为所有物业
					active: "1",
					query: '',
					page: this.pagesObj.page,
					pagesize: this.pagesObj.pagesize,
					type: "WOTEMPLATE"
				}, {
					showLoading: false
				})
				.then((response) => {
					this.loadTable = false
					if (response.data.success) {
						this.getAllList = response.data.content;
						this.$emit('titleNum', response.data.total)
						this.pagesObj = {
							...this.pagesObj,
							page: response.data.page,
							pagesize: this.pagesObj.pagesize,
							total: response.data.total
						}
					} else {
						this.$alert(response.data.message, this.$t('common.alertPrompt'), {
							type: 'error',
							center: true
						})
					}
				})
		},
	}
}
import * as echarts from "echarts";
export default {
	name: 'HomeMeterShowPage',
	props: {
		editData: {
			type: Object,
			default: null
		},
	},
	data() {
		return {
			chartMinDate: '',
			chartPickerOptions: {
				onPick: (time) => {
					this.chartMinDate = time.minDate
				},
				disabledDate: (time) => {
					let thirtyDaysTime = 30 * 24 * 60 * 60 * 1000
					if (this.chartMinDate) {
						return time.getTime() < this.chartMinDate.getTime() - thirtyDaysTime || time.getTime() > this.chartMinDate.getTime() + thirtyDaysTime
					} else {
						return false
					}
				}
			},
			datetime: '',
			// 图表实例
			chartWrapperEchart: null

		}
	},
	mounted() {
		if (this.editData) {
			console.log('editData', this.editData);

			this.setDashboardChart(this.editData)
		}


	},
	methods: {

		changeChartDate(e, item) {
			// 清空时间
			this.chartMinDate = ''
			if (!e) {
				this.datetime = []
				// item.startdate = ''
				// item.enddate = ''
			} else {
				this.confirmDateTimeFilter(item)
			}
		},

		confirmDateTimeFilter(item) {
			let startdate = ''
			let enddate = ''
			if (this.datetime && this.datetime.length > 1) {
				startdate = this.datetime[0]
				enddate = this.datetime[1]
			}
			this.$http.post('/dashboard/getmeterhistory', {
				cmnum: item.cmnum,
				hrid: this.$store.getters.loginid,
				penum: this.$store.getters.penum,
				indexcode: item.indexcode,
				startdate: startdate,
				enddate: enddate
			}).then(res => {
				if (res.data.success) {
					// let key = item.indexcode == 'PIR' ? 'pirChartlist' : 'meterChartList'
					this.chartWrapperEchart && this.chartWrapperEchart.dispose()
					this.chartWrapperEchart = null
					// if (res.data.meterReadestList.length > 0) {
					item.meterreadinglist = res.data.meterReadestList
					this.$nextTick(() => {
						this.setDashboardChart(item)
					})
					// } else {
					// 	item.meterreadinglist = res.data.meterReadestList
					// 	this.$nextTick(() => {
					// 		this.setDashboardChart(item)
					// 	})
					// }
				} else {
					this.$alert(res.data.message, this.$t('common.alertPrompt'), {
						type: 'error',
						center: true
					})
				}
			})
			// item.popperVisible = false
		},
		setDashboardChart(dashboardItem) {
			let nameArr = []
			let valueArr = []
			let meterData = JSON.parse(JSON.stringify(dashboardItem))
			// console.log('this.$refs[dashboardItem.id]',this.$refs[dashboardItem.id],dashboardItem.id)
			if (!this.chartWrapperEchart) {
				this.chartWrapperEchart = echarts.init(this.$refs['chartWrapper'])
			}

			// 红外线
			if (dashboardItem.indexcode == "PIR") {
				// 时间升序
				if (meterData.meterreadinglist) {
					meterData.pirreading = JSON.parse(JSON.stringify(meterData.meterreadinglist))
				}
				meterData.pirreading.sort((a, b) => new Date(a.newreadingdate).getTime() - new Date(b.newreadingdate).getTime())
				meterData.pirreading.forEach(pItem => {
					nameArr.push(pItem.newreadingdate)
					valueArr.push(pItem.newreading)
				})

				if (valueArr.length == 0) {
					this.chartWrapperEchart.clear()
					this.chartWrapperEchart.setOption({
						title: {
							show: true,
							text: this.$t('home.noData'),
							top: 'center',
							left: 'center',
							textStyle: {
								fontSize: 14,
								fontWeight: 400,
							}
						}
					})
				} else {
					this.chartWrapperEchart.setOption({
						title: {
							show: false
						},
						tooltip: {
							show: true,
							confine: true,
						},
						dataZoom: [{
							type: 'inside',
							start: 0
						}],
						xAxis: {
							type: 'category',
							data: nameArr,
						},
						yAxis: {
							type: 'category',
						},
						grid: {
							top: '5%',
							bottom: '15%',
							left: '10%',
							right: '10%',
						},
						series: [
							{
								type: 'line',
								step: 'middle',
								data: valueArr,
								itemStyle: {
									color: '#ffa000'
								},
							}
						]
					});
				}
			} else {
				// 排序（升序）
				meterData.meterreadinglist.sort((a, b) => new Date(a.newreadingdate).getTime() - new Date(b.newreadingdate).getTime())
				// 设置成二维数组
				let dataTwoList = meterData.meterreadinglist.map(e => [e.newreadingdate, e.newreading])
				// 时间列表
				let timeList = dataTwoList.map(time => this.$utils.format(new Date(time[0]), 'Y-m-d H:i'))
				// 数据列表
				let dataList = dataTwoList.map(time => time[1])
				// 求最大值，又来设置颜色取值域的
				let max = Math.max(...meterData.meterreadinglist.map(e => parseFloat(e.newreading)))
				// 测量仪表 折线图
				// 光线  阶梯折线图 smooth: false, series.step: 'end'
				if (dataList.length == 0) {
					this.chartWrapperEchart.clear()
					this.chartWrapperEchart.setOption({
						title: {
							show: true,
							text: this.$t('home.noData'),
							top: 'center',
							left: 'center',
							textStyle: {
								fontSize: 14,
								fontWeight: 400,
							}
						}
					})
					return
				}
				this.chartWrapperEchart.setOption({
					title: {
						show: false
					},
					tooltip: {
						show: true,
						formatter: (params) => {
							return `<div>
							${params.marker}${this.$utils.format(new Date(params.name), 'Y-m-d H:i')}<p style="margin-left: 15px;">${params.value} ${meterData.unit}</p>
							</div>`
						},
						confine: true
					},
					dataZoom: [{
						type: 'inside',
						start: 0
					}],
					grid: {
						top: '5%',
						bottom: '15%',
						left: '10%',
						right: '10%',
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						axisLabel: {
							formatter: (e) => {
								return this.$utils.format(new Date(e), 'Y-m-d H:i')
							}
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed'
							}
						},
						data: timeList
					},
					yAxis: {
						type: 'value',
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed'
							}
						}
					},
					visualMap: {
						show: false,
						pieces: [
							{
								lte: meterData.islowlimit,
								color: '#FF0000'
							},
							{
								lt: meterData.istoplimit,
								gt: meterData.islowlimit,
								color: '#FFA000'
							},
							{
								gt: meterData.istoplimit,
								lte: meterData.istoplimit + max,
								color: '#FF0000'
							}
						],
						outOfRange: {
							color: '#999'
						}
					},
					series: [
						{
							data: dataList,
							type: 'line',
							smooth: dashboardItem.indexcode == 'LIGHT' ? false : true,
							step: dashboardItem.indexcode == 'LIGHT' ? 'end' : false,
							symbolSize: 6,
							lineStyle: {
								width: 1,
							},
							areaStyle: {
								color: 'rgba(255, 160, 0, 0.3)'
							},
							markLine: {
								symbol: [],
								label: {
									show: false
								},
								data: [{
									yAxis: meterData.istoplimit,
									lineStyle: {
										color: '#ff0000',
										type: 'dashed'
									},
								},
								{
									yAxis: meterData.islowlimit,
									lineStyle: {
										color: '#ff0000',
										type: 'dashed'
									},
								}],
								silent: true
							}
						}
					]
				});
			}
			//   })
			// })
		},
	}
}
<template>
  <div class="container">
    <!-- 物业实体 -->
    <template v-if="dialogAccountType == 'pe'">
      <el-table ref="tableRef" :data="getAllList" style="width: 100%" v-loading="loadTable">
        <el-table-column prop="description" :label="$t('homeAccountDetail.peDesc')"> </el-table-column>
        <el-table-column label="Logo">
          <template slot-scope="scope">
            <img :src="scope.row.pelogo" alt="" style="width: 200px" />
          </template>
        </el-table-column>
        <div slot="empty">
          <el-empty :description="$t('account.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
        </div>
      </el-table>
      <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="getHomeList"></pagination-box>
    </template>
    <!-- 使用账号 -->
    <template v-else-if="dialogAccountType == 'acc'">
      <el-table ref="tableRef" :data="getAllList" style="width: 100%" v-loading="loadTable">
        <el-table-column v-if="propertyState" prop="pedesc" :label="$t('homeAccountDetail.peDesc')"> </el-table-column>
        <el-table-column prop="roleaccount" :label="$t('homeAccountDetail.accountId')"> </el-table-column>
        <el-table-column prop="displayname" :label="$t('homeAccountDetail.accountName')"> </el-table-column>
        <el-table-column :formatter="getroleFormatter" :label="$t('homeAccountDetail.accountRole')"> </el-table-column>
        <el-table-column :label="$t('homeAccountDetail.authGroup')">
          <template slot-scope="scope">
            <template v-for="(auth, authindex) in scope.row.authgroup"
              >{{ auth.name }}
              <template v-if="authindex != scope.row.authgroup.length - 1">、</template>
            </template>
          </template>
        </el-table-column>
        <div slot="empty">
          <el-empty :description="$t('account.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
        </div>
      </el-table>
      <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="getAccountList"></pagination-box>
    </template>
    <!-- 巡检点 -->
    <template v-else-if="dialogAccountType == 'loc'">
      <el-table ref="tableRef" :data="getAllList" style="width: 100%" v-loading="loadTable">
        <el-table-column v-if="propertyState" prop="pedesc" :label="$t('homeAccountDetail.peDesc')"> </el-table-column>
        <el-table-column prop="description" :label="$t('location.patPointName')"> </el-table-column>
        <el-table-column prop="locnum" :label="$t('location.patPointNum')"> </el-table-column>
        <el-table-column :formatter="labeltypeFormatter" :label="$t('location.patPointType')"> </el-table-column>
        <el-table-column :label="$t('location.needUseLabel')">
          <template slot-scope="scope">
            {{ scope.row.uselabel == '1' ? $t('common.yes') : $t('common.no') }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('location.isEffective')">
          <template slot-scope="scope">
            {{ scope.row.active == '1' ? $t('common.yes') : $t('common.no') }}
          </template>
        </el-table-column>
        <div slot="empty">
          <el-empty :description="$t('location.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
        </div>
      </el-table>
      <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="getLoclist"></pagination-box>
    </template>
    <!-- 巡检计划 -->
    <template v-else-if="dialogAccountType == 'rctask'">
      <el-table ref="tableRef" :data="getAllList" style="width: 100%" v-loading="loadTable">
        <el-table-column v-if="propertyState" prop="pedesc" :label="$t('homeAccountDetail.peDesc')"> </el-table-column>
        <el-table-column :label="$t('homeAccountDetail.task')" width="300">
          <template slot-scope="scope">
            <p>{{ scope.row.description }}</p>
            <!-- 每 * 小时 产生巡检单 -->
            <p v-if="scope.row.istemp == '0' && scope.row.interval && !scope.row.iscontinuedwo">
              {{ $t('homeAccountDetail.intervalToCreateTempTask', { content: getFrequnit(scope.row.frequnit) }) }}
            </p>
            <p v-if="scope.row.istemp === '1'">{{ $t('homeAccountDetail.tempTask') }}</p>
            <!-- 下次执行巡检时间 -->
            <p v-if="!scope.row.iscontinuedwo">
              {{ $t('homeAccountDetail.nextInstime') }}：
              <template v-if="scope.row.nextdate">
                {{ scope.row.nextdate }}
              </template>
              <template v-else>
                {{ $t('homeAccountDetail.notSet') }}
              </template>
            </p>
          </template>
        </el-table-column>
        <el-table-column :label="$t('task.insman')">
          <template slot-scope="scope">
            <p>
              <template v-if="scope.row.executorname">
                {{ scope.row.executorname }}
              </template>
              <template v-else-if="scope.row.workgroupname">
                {{ scope.row.workgroupname }}
              </template>
              <template v-else>
                {{ $t('homeAccountDetail.unsPec') }}
              </template>
            </p>
            <p v-if="scope.row.reviewername">{{ $t('task.reviewer') }}：{{ scope.row.reviewername }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="statusdesc" :label="$t('homeAccountDetail.status')"> </el-table-column>
        <div slot="empty">
          <el-empty :description="$t('task.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
        </div>
      </el-table>
      <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="getrctaskList"></pagination-box>
    </template>

    <!-- 工单模板 -->
    <template v-else-if="dialogAccountType == 'wotemp'">
      <el-table ref="tableRef" :data="getAllList" style="width: 100%" v-loading="loadTable">
        <el-table-column v-if="propertyState" prop="pedesc" :label="$t('homeAccountDetail.peDesc')"> </el-table-column>
        <el-table-column :label="$t('homeAccountDetail.task')" width="300">
          <template slot-scope="scope">
            <p v-if="scope.row.istemp == '0'"></p>
            <p v-if="scope.row.iscontinuedwo">{{ $t('homeAccountDetail.suswo') }}</p>
            <p>{{ scope.row.description }}</p>
          </template>
        </el-table-column>
        <el-table-column :label="$t('task.insman')">
          <template slot-scope="scope">
            <p>
              <template v-if="scope.row.executorname">
                {{ scope.row.executorname }}
              </template>
              <template v-else-if="scope.row.workgroupname">
                {{ scope.row.workgroupname }}
              </template>
              <template v-else>
                {{ $t('homeAccountDetail.unsPec') }}
              </template>
            </p>
            <p v-if="scope.row.reviewername">{{ $t('task.reviewer') }}：{{ scope.row.reviewername }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="statusdesc" :label="$t('homeAccountDetail.status')"> </el-table-column>
        <div slot="empty">
          <el-empty :description="$t('woTemplate.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
        </div>
      </el-table>
      <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="getwotempList"></pagination-box>
    </template>
  </div>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>

export default {
	name: 'HomeConfigDashboardPage',
	props: {
		chartsyslist: {
			type: Array,
			default: []
		},
		editData: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			dashboardConfig: [],
			// 仪表表格数据
			meterTableData: [],
			// 仪表树形结构配置数据
			meterTreeList: [],
			refreshrateDashboard: 0,
			refreshrateChart: 0,
			selectChartShowData: [],
			chartShowTime: '1',
			meterDialogVisible: false,
			meterQuery: '',
			// 选中的仪表
			meterCheckedList: [],
			// 仪表树形结构分页对象
			meterPagination: {
				page: 1,
				size: 10,
				total: 0
			},
			// 仪表列表分页对象
			meterTablePageObj: {
				page: 1,
				size: 10,
				total: 0
			},
			// 展开节点数据
			meterExpandList: []
		}
	},
	created() {
		this.$nextTick(() => {
			if (this.editData) {
				let editData = JSON.parse(JSON.stringify(this.editData))
				this.dashboardConfig = editData.dashboardConfig
				this.getPaginationMeterList(1)
				this.selectChartShowData = editData.selectChartShowData
				this.refreshrateDashboard = editData.refreshrateDashboard
				this.refreshrateChart = editData.refreshrateChart
				this.chartShowTime = editData.chartShowTime
			}
		})

	},
	methods: {
		// 获取分页后的仪表数据
		getPaginationMeterList(page) {
			if (page) this.meterTablePageObj.page = page
			this.meterTableData = this.setListPagination(this.dashboardConfig, this.meterTablePageObj)
		},
		/**
		 * 设置分页
		 * @param {Array} dataList 数据列表
		 * @param {Object} pagination 分类配置
		 * @returns 返回处理后的数据
		 */
		setListPagination(dataList, pagination) {
			// 总数
			const total = dataList.length
			// 每页显示条数
			const pagesize = pagination.size
			// 页数
			const pageCount = Math.ceil(dataList.length / pagesize) || 1
			// 设置分页配置
			pagination.total = total
			pagination.pages = pageCount
			if (pagination.page > pagination.pages) {
				pagination.page = pagination.page - 1
				return this.setListPagination(dataList, pagination)
			}
			// 进行分页
			const resultList = dataList.slice((pagination.page - 1) * pagesize, pagination.page * pagesize)
			return resultList
		},
		// 确认选择仪表
		confirmMeter() {
			this.dashboardConfig = JSON.parse(JSON.stringify(this.meterCheckedList))
			this.getPaginationMeterList(1)
			this.closeMeterDialog()
		},
		// 关闭仪表选择弹窗
		closeMeterDialog() {
			this.meterDialogVisible = false
			this.meterQuery = ''
		},
		// 删除仪表
		deleteMeterClick(row) {
			this.$utils.blurFn()
			const index = this.dashboardConfig.findIndex(d => d.id === row.id)
			if (index !== -1) {
				this.dashboardConfig.splice(index, 1)
			}
			this.getPaginationMeterList()
		},
		// 仪表选中状态变更
		meterCheckChange(data, param) {
			if (data.children) {
				// 没有选中或没有匹配id的，将清空
				if (!param.checkedKeys.length || !param.checkedKeys.some(cItem => cItem === data.id)) {
					this.meterCheckedList = this.meterCheckedList.filter(m => m.id.indexOf(data.id) === -1)
				} else {
					let list = []
					param.checkedNodes.forEach(c => {
						// 只要新增的子节点
						if (!c.children && !this.meterCheckedList.some(mc => mc.id === c.id)) {
							list.push(c)
						}
					})
					this.meterCheckedList.unshift(...list)
				}
			} else {
				if (param.checkedKeys.some(cItem => cItem === data.id)) {
					this.meterCheckedList.unshift(data)
				} else {
					this.meterCheckedList = this.meterCheckedList.filter(m => m.id !== data.id)
				}
			}
		},
		// 打开仪表弹窗
		openMeterDialog() {
			this.meterCheckedList = JSON.parse(JSON.stringify(this.dashboardConfig))
			this.meterExpandList = this.meterCheckedList.map(d => d.id)
			this.meterDialogVisible = true
			this.$nextTick(() => {
				this.getcmdashboard(1)
			})
		},
		// 获取图表名称
		getChartDescription(type) {
			const descOpt = {
				chart1: this.$t('home.homeRcTaskTit'),
				chart2: this.$t('home.homeWoTaskTit'),
				chart3: this.$t('home.homePeIrTit'),
				chart4: this.$t('home.homeAbLocTit'),
				chart5: this.$t('home.homeHrRcTit'),
			}
			return descOpt[type] || ''
		},
		// 获取仪表配置数据
		getcmdashboard(page) {
			if (page && typeof page !== 'object') {
				this.meterPagination.page = page
			} else {
				this.meterPagination.page = 1
			}
			this.$http.post('/dashboard/getcmdashboard', {
				hrid: this.$store.getters.loginid,
				penum: this.$store.getters.penum,
				pagesize: this.meterPagination.size,
				page: this.meterPagination.page,
				query: this.meterQuery
			}, {
				loadTarget: this.$refs.meterDialogBody
			}).then(result => {
				if (result.data.success) {
					let list = result.data.content
					list.forEach(l => {
						l.id = l.cmnum
						l.label = l.cmdesc
						l.children = l.indexcode
						l.indexcode.forEach(iItem => {
							iItem.id = l.cmnum + '|' + iItem.indexcode
							iItem.label = iItem.description
							iItem.cmdesc = l.cmdesc
						})
					})
					this.meterTreeList = list
					this.meterPagination = {
						...this.meterPagination,
						page: result.data.page,
						total: result.data.total
					}
					this.$nextTick(() => {
						this.$refs.meterTree.setCheckedKeys(this.meterCheckedList.map(d => d.id), true)
					})
				} else {
					this.$alert(result.data.message, this.$t('common.alertPrompt'), {
						type: 'error',
						center: true
					})
				}
			})
		},
		// 提交
		saveConfigClick() {
			// console.log(this.chartShowTime)
			let dashboardcm = []
			let tempdashboardcm = {}
			this.dashboardConfig.forEach(element => {
				let tempArr = element.id.split('|')
				if (!tempdashboardcm[tempArr[0]]) tempdashboardcm[tempArr[0]] = []
				tempdashboardcm[tempArr[0]].push(tempArr[1])
			});
			for (let index in tempdashboardcm) {
				dashboardcm.push({
					"cmnum": index,
					"meternum": "",
					"indexcode": tempdashboardcm[index]
				})

			}

			// 仪表配置
			const setDashboardRequest = new Promise((resolve, reject) => {
				if (!this.refreshrateDashboard) this.refreshrateDashboard = '0'
				this.$http.post('/dashboard/setDashboard', {
					hrid: this.$store.getters.loginid,
					penum: this.$store.getters.penum,
					"dashboardnum": this.editData.dashboardnum,
					"refreshrate": Math.abs(this.refreshrateDashboard) + '',
					"dashboardcm": dashboardcm

				}, {
					loadTrigger: 'pubsub',
				}).then(result => {
					if (result.data.success) {
						resolve();
					} else {
						reject(result)

					}
				})
					.catch(reason => {
						reject(reason)

					});
			})

			// 图表配置
			const setchartuserRequest = new Promise((resolve, reject) => {
				if (!this.refreshrateChart) this.refreshrateChart = '0'
				this.$http.post('/chart/setchartuser', {
					hrid: this.$store.getters.loginid,
					penum: this.$store.getters.penum,
					"timerange": this.chartShowTime + '',
					"refreshrate": Math.abs(this.refreshrateChart) + '',
					"chart": this.selectChartShowData
				}, {
					loadTrigger: 'pubsub',
				}).then(result => {
					if (result.data.success) {
						resolve();
					} else {
						reject(result)

					}
				})
					.catch(reason => {
						reject(reason)

					});
			})

			Promise.all([setchartuserRequest, setDashboardRequest]).then(() => {
				this.$pubsub.publish('closeLoadEvent')
				// console.log(result)
				this.$emit('setSuccess', this.selectChartShowData)

				// this.chartShowData = this.selectChartShowData
				// this.getchartsyslist(() => {
				// 	this.chartlist = []
				// 	this.chartShowData.forEach((element) => {
				// 		this.getchartlist([element])
				// 	})

				// })
				// this.getDashboard()

			}).catch(reason => {
				this.$pubsub.publish('closeLoadEvent')
				if (reason.data && reason.data.message) {
					this.$alert(reason.data.message, this.$t('common.alertPrompt'), {
						type: 'error',
						center: true
					})
				}

			});
		},
		cancelConfigClick() {
			this.$emit('cancelConfigClick')
		}
	}
}